var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "import-container" },
    [
      _c(
        "import-template",
        {
          attrs: {
            uploadUrl: _vm.importExcelUrl,
            downloadUrl: _vm.downloadUrl,
            uploadLabel: "项目数据",
          },
        },
        [
          _c("div", { attrs: { slot: "remark" }, slot: "remark" }, [
            _c(
              "div",
              { staticClass: "remark" },
              [
                _vm._l(_vm.showTitleList, function (item, index) {
                  return _c("p", { key: index }, [_vm._v(_vm._s(item) + ";")])
                }),
                _c("p", [_vm._v("请在excel中按以上顺序填写数据")]),
                _c("p", [_vm._v("最大支持10MB的excel文件，超过请拆分导入")]),
              ],
              2
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }