// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}communityInfo/getAdminCommunityList`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}communityInfo/exportCommunity`
// 获取组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgTag/getDropDownSearchTag`
// 获取收款单位
const getlegalUnitURL = `${API_CONFIG.butlerBaseURL}legalUnit/legalUnitSelectTo`
// 批量操作
const batchURL = `${API_CONFIG.baseURL}smUserAccountAction!batchUpdateRechargeCardStatus.action`
// 获取项目阶段ops
const getProjectTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 获取项目类型ops
const getRoomTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getRoomTypes.action`
// 基础信息新增
const addCommunityBaseInfoURL = `${API_CONFIG.butlerBaseURL}communityInfo/addCommunityBaseInfo`
// 基础信息更新
const updateCommunityBaseInfoURL = `${API_CONFIG.butlerBaseURL}communityInfo/updateCommunityBaseInfo`
// 业务组件更新
const updateCommunityBusinessInfoURL = `${API_CONFIG.butlerBaseURL}communityInfo/updateCommunityBusinessInfo`
// 项目展示更新
const updateCommunityIntroInfoURL = `${API_CONFIG.butlerBaseURL}communityInfo/updateCommunityIntroInfo`
// 上传图片地址
const uploadURL = `${API_CONFIG.uploadURL}?module=shopgoods`
// 获取项目详情
const getCommunityInfoURL = `${API_CONFIG.butlerBaseURL}communityInfo/getCommunityInfo`
// 获取标签列表
const getOrgTagListURL = `${API_CONFIG.butlerBaseURL}orgTag/list`
// 获取一级标签列表
const getSuperiorTagListURL = `${API_CONFIG.butlerBaseURL}orgTag/getSuperiorTagByTagName`
// 获取临时开票账号
const getTicketAccountURL = `${API_CONFIG.butlerBaseURL}invoice/config/communityId`
// 上传接口
const uploadUrl = `${API_CONFIG.uploadURL}?module=excel`
// 导入接口
const importExcelUrl = `${API_CONFIG.butlerBaseURL}communityInfo/importCommunityInfo`

// 获取战役配置接口
const getCommunitySettings = `${API_CONFIG.butlerBaseURL}emergency/getCommunitySettings`
// 保存战役配置接口
const setCommunitySettings = `${API_CONFIG.butlerBaseURL}emergency/setCommunitySettings`
// 根据租户获取组织标签
const getOrgTagByTenant = `${API_CONFIG.butlerBaseURL}user/orgTag/list/tenantOrgTags`

// 获取导入模板的url  园区信息导入模板
const getImportTemplateURL = `${API_CONFIG.butlerBaseURL}communityInfo/getImportTemplate`
const getAreaListUrl = `${API_CONFIG.butlerBaseURL}common/getAreaList`

// 项目人员类型配置列表/special/labelListByEntranceRule
const getConfigTypeList = `${API_CONFIG.butlerBaseURL}special/peopleTypeConfigurationList`
// 删除，停用项目人员类型配置
const deleteOrStopPeople = `${API_CONFIG.butlerBaseURL}special/deletePeopleTypeConfiguration`
// 人员类型标签列表
const getPeopleLabel = `${API_CONFIG.butlerBaseURL}special/labelListByEntranceRule`
// 项目人员录入信息列表
const getPeopleTypeList = `${API_CONFIG.butlerBaseURL}special/peopleTypeCodewordList`
// 新增人员类型配置
const addPeopleType = `${API_CONFIG.butlerBaseURL}special/addPeopleTypeConfiguration`
// 项目人员类型排序交换
const exchangeSort = `${API_CONFIG.butlerBaseURL}special/exchangeSort`
// 恢复项目人员类型配置
const recoverPeople = `${API_CONFIG.butlerBaseURL}special/recoverPeopleTypeConfiguration`
// 获取小程序二维码
const getMiniQrcode = `${API_CONFIG.butlerBaseURL}special/getQrCode`
// 获取扩展数据
const getcommunityAllInfoURL = `${API_CONFIG.butlerBaseURL}communityInfo/getcommunityAllInfo`
// 扩展数据基础信息导入接口
const importCommunityInfoBaseUrl = `${API_CONFIG.butlerBaseURL}communityInfo/importCommunityInfoBase`
// 扩展数据财务信息导入接口
const importCommunityInfoFinanceUrl = `${API_CONFIG.butlerBaseURL}communityInfo/importCommunityInfoFinance`
// 扩展数据业户信息导入接口
const importCommunityInfoOwnerUrl = `${API_CONFIG.butlerBaseURL}communityInfo/importCommunityInfoOwner`
// 扩展数据基础信息导入接口
const importCommunityInfoAreaUrl = `${API_CONFIG.butlerBaseURL}communityInfo/importCommunityInfoArea`
// 扩展数据基础信息导入接口
const importCommunityInfoEquipmentUrl = `${API_CONFIG.butlerBaseURL}communityInfo/importCommunityInfoEquipment`
// 扩展数据基础信息导入接口
const importCommunityInfoParkUrl = `${API_CONFIG.butlerBaseURL}communityInfo/importCommunityInfoPark`
// 扩展数据导出列表
const exportImproveDataURL = `${API_CONFIG.butlerBaseURL}communityInfo/exportCommunityExpand?type=1`
const exportImproveDataURL2 = `${API_CONFIG.butlerBaseURL}communityInfo/exportCommunityExpand?type=3`
const exportImproveDataURL3 = `${API_CONFIG.butlerBaseURL}communityInfo/exportCommunityExpand?type=2`
const exportImproveDataURL4 = `${API_CONFIG.butlerBaseURL}communityInfo/exportCommunityExpand?type=4`
const exportImproveDataURL5 = `${API_CONFIG.butlerBaseURL}communityInfo/exportCommunityExpand?type=5`
const exportImproveDataURL6 = `${API_CONFIG.butlerBaseURL}communityInfo/exportCommunityExpand?type=6`
// 项目标签列表信息
const multiSelectUrl = `${API_CONFIG.controlBaseURL}label/getLabelList?status=1&labelType=8`
// 新湖缴费规则设置接口
// 获取新湖园区的收费规则 设置
const getCommunityChargeRuleListUrl = `${API_CONFIG.butlerBaseURL}communityInfo/getCommunityChargeRuleList`
// 搜索新湖公司的科目
const getCommunityChargeSubjectListUrl = `${API_CONFIG.butlerBaseURL}communityInfo/getCommunityChargeSubjectList`
// 新增/修改项目缴费规则
const updateCommunityChargeRuleUrl = `${API_CONFIG.butlerBaseURL}communityInfo/updateCommunityChargeRule`
// 新增未注册用户
const createSubmitUrl = `${API_CONFIG.butlerBaseURL}user/register/addRegisterUserBase`
// 新修改信息接口
const updateSubmitUrl = `${API_CONFIG.butlerBaseURL}user/register/updateRegisterUserBase`
// 二级分类
const getCategoryIdUrl = `${API_CONFIG.controlBaseURL}label/getCategoryByParentId`
export {
  getCategoryIdUrl,
  updateSubmitUrl,
  createSubmitUrl,
  multiSelectUrl,
  setCommunitySettings,
  getCommunitySettings,
  getListURL,
  exportListURL,
  getOrgListURL,
  getlegalUnitURL,
  batchURL,
  getProjectTypesURL,
  getRoomTypesURL,
  addCommunityBaseInfoURL,
  updateCommunityBaseInfoURL,
  updateCommunityBusinessInfoURL,
  updateCommunityIntroInfoURL,
  uploadURL,
  getCommunityInfoURL,
  getOrgTagListURL,
  getSuperiorTagListURL,
  getTicketAccountURL,
  uploadUrl,
  importExcelUrl,
  getOrgTagByTenant,
  getImportTemplateURL,
  getAreaListUrl,
  getConfigTypeList,
  deleteOrStopPeople,
  getPeopleLabel,
  getPeopleTypeList,
  addPeopleType,
  exchangeSort,
  recoverPeople,
  getMiniQrcode,
  getcommunityAllInfoURL,
  importCommunityInfoBaseUrl,
  importCommunityInfoFinanceUrl,
  importCommunityInfoOwnerUrl,
  importCommunityInfoAreaUrl,
  importCommunityInfoEquipmentUrl,
  importCommunityInfoParkUrl,
  exportImproveDataURL,
  exportImproveDataURL2,
  exportImproveDataURL3,
  exportImproveDataURL4,
  exportImproveDataURL5,
  exportImproveDataURL6,
  getCommunityChargeRuleListUrl,
  getCommunityChargeSubjectListUrl,
  updateCommunityChargeRuleUrl,
}
