<template>
  <div class="import-container">
    <import-template
      :uploadUrl="importExcelUrl"
      :downloadUrl="downloadUrl"
      uploadLabel="项目数据"
      >
      <div slot="remark">
        <div class="remark">
          <p v-for="(item, index) in showTitleList" :key="index">{{item}};</p>
          <p>请在excel中按以上顺序填写数据</p>
          <p>最大支持10MB的excel文件，超过请拆分导入</p>
        </div>
      </div>
    </import-template>
  </div>
</template>
<script>
import { ImportTemplate } from 'components/bussiness/index'
import { importExcelUrl, getImportTemplateURL } from './api'
export default {
  components: {
    ImportTemplate
  },
  data () {
    return {
      importExcelUrl,
      downloadUrl: '/static/excel/园区信息导入模板.xlsx',
      showTitleList: []
    }
  },
  created () {
    this.$setBreadcrumb('导入')
    this.requestForImportTemplateURL()
  },
  methods: {
    // 获取导入模板的url
    async requestForImportTemplateURL () {
      const { data, status } = await this.$axios.get(getImportTemplateURL)
      if (status === 100) {
  
        this.showTitleList = data.showTitleList || []
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .remark{
    p {
      font-style: italic;
    }
  }
</style>
